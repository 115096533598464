import { api, returningError } from 'src/api';
import { store } from 'src/redux';
import { getEndpointPrefix } from 'src/utils';

interface CustomerRequestI {
    name: string;
    lastnames: string;
    email: string;
    phone: string;
    password: string;
}

export const postCustomer = async (body: CustomerRequestI) => {
    try {
        const { data }:any = await api.post(`/${getEndpointPrefix(store.getState().app.locale) || 'es'}/v1/register`, {
            customer: body
        });
        return {
            success: 'true',
            ...data
        };
    } catch (error:any) {
        return returningError(error);
    }
}