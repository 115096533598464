import { store } from 'src/redux';
import { api, returningError } from 'src/api';
import { getEndpointPrefix } from 'src/utils';

export const getCategories = async () => {
    try {
        const { data }:any = await api.get(`/${getEndpointPrefix(store.getState().app.locale) || 'es'}/v1/categories`, {
            params: {
                raw: true
            }
        });
        return { ...data, success: 'true' };
    } catch (error:any) {
        return returningError(error);
    }
}