import React, { Fragment } from 'react';

import { Button, Col, Divider, Layout, Row, Space } from 'antd';
import { FacebookFilled, InstagramOutlined, WhatsAppOutlined } from '@ant-design/icons';

import { glLogo, glEnLogo } from 'src/assets/images';

import LayoutI18n from 'src/i18n/layout.i18n.json';
import styles from './Footer.module.css';
import { useAppSelector } from 'src/hooks';
import { AppI } from 'src/redux';
import { Link } from 'react-router-dom';


const Links = ({ locale }: { locale: AppI['locale'] }) => {
    switch (locale) {
        case 'es-ES':
            return (<>
                <a href='https://www.facebook.com/share/1DSQdEe7xV/' target='_blank' rel='noreferrer'><Button className={styles.social_btn} style={{ backgroundColor: '#BEA89B', marginInline: 10 }} shape='circle' icon={<FacebookFilled style={{ color: '#FFFFFF', fontSize: 25, paddingTop: 3 }} />} size={'large'} /></a>
                <a href='https://www.instagram.com/goodlookspain?igsh=MWIxbWR5aHVuNm1qYQ==' target='_blank' rel='noreferrer'><Button className={styles.social_btn} style={{ backgroundColor: '#BEA89B', marginInline: 10 }} shape='circle' icon={<InstagramOutlined style={{ color: '#FFFFFF', fontSize: 25, paddingTop: 3 }} />} size={'large'} /></a>
                <a href='https://wa.me/34613413612' target='_blank' rel='noreferrer'><Button className={styles.social_btn} style={{ backgroundColor: '#BEA89B', marginInline: 10 }} shape='circle' icon={<WhatsAppOutlined style={{ color: '#FFFFFF', fontSize: 25, paddingTop: 3 }} />} size={'large'} /></a>
            </>)
        case 'en':
            return (<>
                <a href='https://www.facebook.com/share/1BdARkdhQb/' target='_blank' rel='noreferrer'><Button className={styles.social_btn} style={{ backgroundColor: '#BEA89B', marginInline: 10 }} shape='circle' icon={<FacebookFilled style={{ color: '#FFFFFF', fontSize: 25, paddingTop: 3 }} />} size={'large'} /></a>
                <a href='https://www.instagram.com/goodlookusa?igsh=MWZkZWNoNGY2OHR2Zw==' target='_blank' rel='noreferrer'><Button className={styles.social_btn} style={{ backgroundColor: '#BEA89B', marginInline: 10 }} shape='circle' icon={<InstagramOutlined style={{ color: '#FFFFFF', fontSize: 25, paddingTop: 3 }} />} size={'large'} /></a>
                <a href='https://wa.me/12108895509' target='_blank' rel='noreferrer'><Button className={styles.social_btn} style={{ backgroundColor: '#BEA89B', marginInline: 10 }} shape='circle' icon={<WhatsAppOutlined style={{ color: '#FFFFFF', fontSize: 25, paddingTop: 3 }} />} size={'large'} /></a>
            </>)
        case 'es':
        default:
            return (<>
                <a href='https://www.facebook.com/goodlookmx?mibextid=LQQJ4d' target='_blank' rel='noreferrer'><Button className={styles.social_btn} style={{ backgroundColor: '#BEA89B', marginInline: 10 }} shape='circle' icon={<FacebookFilled style={{ color: '#FFFFFF', fontSize: 25, paddingTop: 3 }} />} size={'large'} /></a>
                <a href='https://instagram.com/goodlookmexico?igshid=MzRlODBiNWFlZA==' target='_blank' rel='noreferrer'><Button className={styles.social_btn} style={{ backgroundColor: '#BEA89B', marginInline: 10 }} shape='circle' icon={<InstagramOutlined style={{ color: '#FFFFFF', fontSize: 25, paddingTop: 3 }} />} size={'large'} /></a>
                <a href='https://wa.me/5510790688' target='_blank' rel='noreferrer'><Button className={styles.social_btn} style={{ backgroundColor: '#BEA89B', marginInline: 10 }} shape='circle' icon={<WhatsAppOutlined style={{ color: '#FFFFFF', fontSize: 25, paddingTop: 3 }} />} size={'large'} /></a>
            </>)
    }
} 

const Footer = () => {
    const { lang, locale } = useAppSelector(({ app }: { app: AppI }) => app);
    return (
        <Layout.Footer className={`container ${styles.footer}`}>
            <Row gutter={[8,12]}>
                <Col className={styles.col_footer_contact} 
                    xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 24, order: 1 }}
                    lg={{ span: 8, order: 1 }} xl={{ span: 8, order: 1 }} xxl={{ span: 8, order: 1 }}
                >
                    <h4>{LayoutI18n[lang].contactText}</h4>
                    <br />
                    {LayoutI18n[lang].contactList.map((i, index) => (
                        <Fragment key={index}>
                            <h5>{i.title}</h5>
                            <p>{typeof i.description === 'string' ? i.description : i.description[locale]}</p>
                        </Fragment>
                    ))}
                </Col>
                <Col className={styles.col_footer_logo} 
                    xs={{ span: 24, order: 3 }} sm={{ span: 24, order: 3 }} md={{ span: 24, order: 3 }}
                    lg={{ span: 8, order: 2 }} xl={{ span: 8, order: 2 }} xxl={{ span: 8, order: 2 }}
                >
                    <img src={lang === 'es' ? glLogo : glEnLogo} alt='gl-logo' width={300} height={95} />
                    <div style={{ marginBlock: 15 }}>
                        <Links
                            locale={locale}
                        />
                    </div>
                </Col>
                <Col className={styles.col_footer_schedule} 
                    xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} md={{ span: 24, order: 2 }}
                    lg={{ span: 8, order: 3 }} xl={{ span: 8, order: 3 }} xxl={{ span: 8, order: 3 }}
                >
                    <h4>{LayoutI18n[lang].schedulesText}</h4>
                    <p>8:30am - 10:00pm {LayoutI18n[lang].workDayScheduleText}</p>
                    <p>7:00am - 8:00pm {LayoutI18n[lang].saturdayScheduleText}</p>
                    <p>10:00am - 8:00pm {LayoutI18n[lang].sundayScheduleText}</p>
                    <p><a href='/terminos-uso' target='_blank' rel='noreferrer'>{LayoutI18n[lang].useTermsText}</a></p>
                    <p><a href='/politica-privacidad' target='_blank' rel='noreferrer'>{LayoutI18n[lang].privacyPolicyText}</a></p>
                    <br />
                    <p><Link to='/formulario-goodlookeros'>{LayoutI18n[lang].glUseTermsText}</Link></p>
                </Col>
            </Row>
            <Divider />
            <Space style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                goodlook © 2021 All Rights Reserved.
            </Space>
        </Layout.Footer>
    )
}
export default Footer;
