import { api, returningError } from 'src/api';
import { UserRequestI, store } from 'src/redux';
import { getEndpointPrefix } from 'src/utils';

export const postSignIn = async (body: UserRequestI) => {
    try {
        const { data }:any = await api.post(`/${getEndpointPrefix(store.getState().app.locale) || 'es'}/v1/sign_in`, body);
        return data;
    } catch (error:any) {
        return returningError(error);
    }
}