import React, { useEffect, useState } from 'react';

import { Button, Col, Row, notification } from 'antd';

import styles from './ServiceDetail.module.css';
import { convertToHourMinutes, getPriceByLocale, ucFirst } from 'src/utils';
import { AppI, CurrentProductI } from 'src/redux';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { setProdCart } from 'src/redux/cart/cartSlice';

import detServI18n from 'src/i18n/detailservice.i18n.json';
import notifI18n from 'src/i18n/notifications.i18n.json';

interface ServiceDetailsI {
   titleService: string;
   typeService: string;
   currentProduct: CurrentProductI;
}

const ServiceDetails = ({ titleService, typeService, currentProduct }: ServiceDetailsI) => {
   const { lang, locale } = useAppSelector(({ app }: { app: AppI }) => app);
   const dispatch = useAppDispatch();

   const [width, setWidth] = useState(window.innerWidth);

   const formatter =
      locale === 'es-ES'
         ? new Intl.NumberFormat('sfb', {
              style: 'currency',
              currency: 'EUR',
           })
         : 
            new Intl.NumberFormat('en-US', {
               style: 'currency',
               currency: locale === 'es' ? 'MXN' : 'USD',
            });

   useEffect(() => {
      window.addEventListener('resize', () => {
         setWidth(window.innerWidth);
      });
      return () => window.removeEventListener('resize', () => {});
   }, []);

   return (
      <div className={styles.service_detail}>
         <div style={{ position: 'absolute', top: 104, left: 0, right: 0, backgroundColor: '#DDDBD1', height: '60vh', zIndex: 1 }} />
         <div className='container pt-5 text-center' style={{ zIndex: 2, position: 'relative' }}>
            <h2>
               {titleService ? ucFirst(titleService) : ''}
               {/* {typeService ? ucFirst(typeService) : ''} */}
            </h2>
            <Row className={styles.service_detail_row}>
               <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <img
                     style={{ objectFit: 'cover' }}
                     width={'100%'}
                     height={width < 768 ? '400px' : '480px'}
                     alt='img-detail'
                     src={currentProduct.photo}
                  />
               </Col>
               <Col className='p-4' style={{ backgroundColor: '#FFFFFF' }} xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <div style={{ height: '50%' }}>
                     <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                           <p>{convertToHourMinutes(currentProduct.duration)}</p>
                        </div>
                        <div>
                           <p>
                              {formatter
                                 .format(currentProduct[getPriceByLocale(locale)])
                                 .replace('MX', '')}
                           </p>
                        </div>
                     </div>
                     <br />
                     <h3 style={{ textAlign: 'left' }}>{currentProduct.name[lang]}</h3>
                     <p style={{ textAlign: 'left' }}>{currentProduct.description[lang]}</p>
                  </div>
                  <div style={{ height: '50%', display: 'flex', alignItems: 'flex-end', justifyContent: 'center', paddingBottom: 50 }}>
                     <Button
                        style={{ width: '60%' }}
                        type='primary'
                        ghost
                        size='large'
                        onClick={() => {
                           notification.success({
                              message: notifI18n[lang].serviceTitleNotification,
                              description: notifI18n[lang].serviceDescriptionNotification,
                              placement: 'topLeft',
                           });
                           dispatch(
                              setProdCart({
                                 id: currentProduct.id,
                                 nameProduct: currentProduct.name,
                                 numberProducts: 1,
                                 cost:
                                    currentProduct[getPriceByLocale(locale)],
                                 uri: currentProduct.photo,
                              })
                           );
                        }}>
                        {detServI18n[lang].btnAddService}
                     </Button>
                  </div>
               </Col>
            </Row>
         </div>
      </div>
   );
};

export default ServiceDetails;
