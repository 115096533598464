import React, { useState } from 'react';
import { CardElement } from '@stripe/react-stripe-js';

interface CardSectionI {
    callbackComplete: (complete:boolean) => void;
    hideZip?: boolean;
}

const  CardSection = ({ callbackComplete, hideZip = false }: CardSectionI) => {

    const [state, setState] = useState({
        'error': false,
        'errorMessage': ''
    })

    const card_element_options = {
        hidePostalCode: hideZip,
        style: {
            base: {
                color: "#32325d",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#aab7c4",
                },
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
            },
        }
    }

    const handleOnChange = (event:any) => {
        setState({...state, errorMessage: ''})
        
        callbackComplete(event.complete)
        
        if (event.error) {
            setState({error: true, errorMessage:event.error.message})
        }
    }

    return (
        <>
            <CardElement options={card_element_options} onChange={handleOnChange}/>
            <div className="card-errors">
                <p>{state.errorMessage}</p>
            </div>
        </>
    )
}

export default CardSection