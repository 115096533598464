import { store } from 'src/redux';
import { api, returningError } from 'src/api';
import { getEndpointPrefix } from 'src/utils';

export const getZones = async () => {
    try {
        const { data }:any = await api.get(`/${getEndpointPrefix(store.getState().app.locale) || 'es'}/v1/zones`);
        return { ...data, success: 'true' };
    } catch (error:any) {
        return returningError(error);
    }
}

type LocateZoneT = {
    locality: string;
    administrative_area_level_1?: string;
    administrative_area_level_2?: string;
    sublocality?: string;
    neighborhood?: string;
}

export const getLocateYourZone = async (body: LocateZoneT) => {
    try {
        const { data }: any = await api.get(`/${getEndpointPrefix(store.getState().app.locale) || 'es'}/v1/locate`, {
            params: body
        });
        return { ...data, success: 'true' };
    } catch (error:any) {
        console.log(error);
        returningError(error);
    }
}