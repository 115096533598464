import React, { useEffect, useState } from 'react';

import { Col, Row, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';

import { glLogo, glEnLogo, glWhiteEnLogo, glWhiteLogo } from 'src/assets/images';
import { SearchAutocomplete, Select } from 'src/components';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
   AppI,
   CommonI,
   setLocale,
   setCurrentService,
   setLang,
   CityI,
   setCurrentZone,
   setCurrentProduct,
   initialStateProducts,
   initialStateZone,
} from 'src/redux';
import SelectLanguage from 'src/pages/auth/SelectLanguage';
import { getOption } from 'src/pages/compositions';

import homeI18n from 'src/i18n/home.i18n.json';
import styles from 'src/pages/home/Home.module.css';

interface HeroI {
   isRef?: boolean;
   onBlurRef?: () => void;
}

const Hero = ({ isRef, onBlurRef }: HeroI) => {
   const dispatch = useAppDispatch();
   const { currentZone, services, cities: citiesZ } = useAppSelector(({ common }: { common: CommonI }) => common);
   const { lang, locale } = useAppSelector(({ app }: { app: AppI }) => app);
   const navigate = useNavigate();

   const [city, setCity] = useState(currentZone.id ? String(currentZone.id) : '00');
   const [cities, setCities] = useState<Array<CityI>>([]);
   const [service, setService] = useState('00');
   const [width, setWidth] = useState(window.innerWidth);

   useEffect(() => {
      window.addEventListener('resize', () => {
         setWidth(window.innerWidth);
      });
      return () => window.removeEventListener('resize', () => {});
   }, []);

   useEffect(() => {
      setService('00');
      dispatch(setCurrentService(''));
   }, []);

   useEffect(() => {
      if (citiesZ.length > 0) {
         const arr = citiesZ.filter((i) => i.country_id === 2);
         setCities(arr);
      }
   }, [locale]);

   useEffect(() => {
      if (city === '00') {
         dispatch(setCurrentProduct(initialStateProducts));
         dispatch(setCurrentZone(initialStateZone));
      }
   }, [city]);

   return (
      <>
         <div className={styles.small_hero} />
         <Row className={styles.row_hero}>
            <Col
               style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
               xs={24}
               sm={24}
               md={{ offset: 4, span: 16 }}
               lg={{ offset: 2, span: 10 }}
               xl={{ offset: 1, span: 7 }}
               xxl={{ offset: 1, span: 7 }}>
               <img
                  src={width < 992 ? (lang === 'es' ? glLogo : glEnLogo) : lang === 'es' ? glWhiteLogo : glWhiteEnLogo}
                  alt={'logo'}
                  width={270}
                  height={95}
               />
               <Tooltip
                  placement='bottom'
                  color='#FFFFFF'
                  title={
                     <SelectLanguage
                        locale={locale}
                        lang={lang}
                        onChangeLocale={(value) => dispatch(setLocale(value))}
                        onChangeLang={(value) => dispatch(setLang(value))}
                     />
                  }>
                  <div className={styles.select_locale}>{getOption(locale, lang, styles.flags)}</div>
               </Tooltip>
               {(locale === 'es') && (
                  <SearchAutocomplete
                     styleContainer={{
                        marginBottom: 24,
                     }}
                     styleInput={{
                        width: 300,
                     }}
                     styleBoxPredictions={{
                        backgroundColor: '#FFF',
                        zIndex: 10,
                        width: 300,
                        borderRadius: 10,
                     }}
                     placeholder={homeI18n[lang].selectPlaceholderCity}
                     isRef={isRef ? isRef : false}
                     onBlurRef={() => onBlurRef?.()}
                     locale={locale}
                  />
               )}
               {(locale === 'co') && (
                  <SearchAutocomplete
                     styleContainer={{
                        marginBottom: 24,
                     }}
                     styleInput={{
                        width: 300,
                     }}
                     styleBoxPredictions={{
                        backgroundColor: '#FFF',
                        zIndex: 10,
                        width: 300,
                        borderRadius: 10,
                     }}
                     placeholder={homeI18n[lang].selectPlaceholderCity}
                     isRef={isRef ? isRef : false}
                     onBlurRef={() => onBlurRef?.()}
                     locale={locale}
                  />
               )}
               {locale === 'en' && (
                  <SearchAutocomplete
                     styleContainer={{
                        marginBottom: 24,
                     }}
                     styleInput={{
                        width: 300,
                     }}
                     styleBoxPredictions={{
                        backgroundColor: '#FFF',
                        zIndex: 10,
                        width: 300,
                        borderRadius: 10,
                     }}
                     placeholder={homeI18n[lang].selectPlaceholderCity}
                     isRef={isRef ? isRef : false}
                     onBlurRef={() => onBlurRef?.()}
                     locale={locale}
                  />
               )}
               {locale === 'es-ES' && (
                  <SearchAutocomplete
                     styleContainer={{
                        marginBottom: 24,
                     }}
                     styleInput={{
                        width: 300,
                     }}
                     styleBoxPredictions={{
                        backgroundColor: '#FFF',
                        zIndex: 10,
                        width: 300,
                        borderRadius: 10,
                     }}
                     placeholder={homeI18n[lang].selectPlaceholderCity}
                     isRef={isRef ? isRef : false}
                     onBlurRef={() => onBlurRef?.()}
                     locale={locale}
                  />
               )}
               {currentZone.locality && (
                  <Select
                     placeholder={homeI18n[lang].selectPlaceholderServices}
                     style={{ minWidth: 300 }}
                     options={
                        services.length > 0
                           ? [
                                { value: '00', label: homeI18n[lang].selectChooseService },
                                ...services.map((i: any) => ({ value: `${i.id}`, label: i.name[lang] || 'Sin Nombre' })),
                             ]
                           : [{ value: '00', label: homeI18n[lang].selectNoResult }]
                     }
                     onChange={(value) => {
                        setService(value);
                        dispatch(setCurrentService(value !== '00' ? value : ''));
                        if (value !== '00') navigate(`servicios/${value}`);
                     }}
                     value={service}
                  />
               )}
            </Col>
         </Row>
      </>
   );
};
export default Hero;
